
import React from 'react';
import { motion } from 'framer-motion';
import { Marker } from 'react-simple-maps';
import pointerMob from '../images/mobileIcon.svg';
import pointerWeb from '../images/webIcon.svg';
import pointerBackend from '../images/backendIcon.svg';
import pointerOjt from '../images/ojt.svg';
import pointerNotification from '../images/notification.svg';

function User({ userID }) {
  try {
    if (!userID || userID === undefined) {
      console.error("UserID is null or not object");
      return null;
    }

    const { lat, long, device, user_name, app_name } = userID;
    // Latitude should be positive and longitude should be less than -80
    var latitude = (lat && lat > 0) ? lat : "";
    var longitude = (long && long < -80) ? long : "";
    var dev = device ? device : "";
    var username = user_name ? user_name : "";
    var appname = app_name ? app_name : "";
  } catch (e) {
    console.error("Error getting user location :", e);
  }

  const imageVariants = {
    stepOne: { scale: 1 },
    stepTwo: {
      scale: 1,
    },
    stepThree: {
      scale: 1,
    },
    stepFourFive: {
      scale: 1,
    },
    stepSix: {
      scale: 1,
    },
    stepSeven: {
      scale: 1,
    },
  };

  return ((
    <>
      {longitude && latitude && (
        <>
        <Marker coordinates={[longitude, latitude]}>
          <motion.image
            initial={{ scale: 0.9 }}
            variants={imageVariants}
            href={
              dev === "backend"
                ? pointerWeb
                : dev === "mobile"
                  ? pointerMob
                  : dev === "web"
                    ? pointerBackend
                    : dev === "notification"
                      ? pointerNotification
                      : dev === "ojt"
                        ? pointerOjt
                        : ''
            }
            className={'test'}
            onClick={() => {
              alert("User Name: " + username + "\nApp Name:  " + appname
              );
            }}
          />
          <text
            textAnchor="middle"
            y={35}
            style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
          >
          </text>
        </Marker>
        </>
      )}
    </>
  ));
}

export default React.memo(User);
