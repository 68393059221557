import React, { useEffect, useState } from 'react';
import db from '../firebase';
import './Map.css';
import User from './User';
import moment from 'moment'

import { ComposableMap, Geographies, Geography } from 'react-simple-maps';

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json';

const Map = ({ setTotalUsers }) => {
  let onlineUsersRef = db.ref('/analytics/');
  const [onlineUsers, setOnlineUsers] = useState([]);
  // add online users -> used to fetch usersinformation

  useEffect(() => {
    onlineUsersRef.on('value', (snapshot) => {
      const dummyData = {
        register_member: {},
        unregister_member: {}
      }
      const currentUsersArray = [];
      const data = (snapshot !== undefined && snapshot?.val() !== undefined && snapshot?.val()) ? snapshot?.val() : dummyData;

      var count_array = [];
      count_array['backend'] = 0;
      count_array['web'] = 0;

      var register_data = data?.register_member || {};
      var unregister_data = data?.unregister_member || {};

      if (register_data) {
        for (var reg_key in register_data) {
          if (register_data[reg_key]?.device) {
            var reg_device = register_data[reg_key]?.device;
            count_array[reg_device] = parseInt(count_array[reg_device]) + 1
          }

          const current_time = moment.utc().format('YYYY-MM-DD HH:mm:ss');
          var userRef = db.ref('/analytics/register_member/')
          const user_time = register_data[reg_key]?.timestamp;
          var ms = moment(current_time, 'YYYY-MM-DD HH:mm:ss').diff(moment(user_time, 'YYYY-MM-DD HH:mm:ss'));
          var d = moment.duration(ms);
          if (d.minutes() > 5) {
            userRef.remove();
          }
          currentUsersArray.push(register_data[reg_key]);
        }
      } else {
        console.error("Registered Data is not an array or object");
      }

      if (unregister_data) {
        for (var unreg_key in unregister_data) {
          if (unregister_data[unreg_key]?.device) {
            var unreg_device = unregister_data[unreg_key]?.device;
            count_array[unreg_device] = parseInt(count_array[unreg_device]) + 1
          }
          const current_time = moment.utc().format('YYYY-MM-DD HH:mm:ss');
          var userRef2 = db.ref('/analytics/unregister_member/')
          const user_time = unregister_data[unreg_key]?.timestamp;
          var ms2 = moment(current_time, 'YYYY-MM-DD HH:mm:ss').diff(moment(user_time, 'YYYY-MM-DD HH:mm:ss'));
          var d2 = moment.duration(ms2);
          if (d2.minutes() > 5) {
            userRef2.remove();
          }

          currentUsersArray.push(unregister_data[unreg_key]);
        }
      } else {
        console.error("Unregistered Data is not an array or object");
      }

      if (data !== undefined) {
        for (let user in data) {
          currentUsersArray.push(data[user]);
        }
      } else {
        console.error("Data is not an array or object");
      }

      setOnlineUsers(currentUsersArray);
      setTotalUsers(currentUsersArray?.length);
    });
  }, []);

  return (
    <ComposableMap projection='geoAlbersUsa'>
      {geoUrl && (
        <Geographies geography={geoUrl}>
          {({ geographies }) => (
            <>
              {geographies?.length > 0 && geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  stroke='#ffffff61'
                  geography={geo}
                  fill='#328CD5'
                />
              ))}
            </>
          )}
        </Geographies>
      )}
      {onlineUsers?.length > 0 && onlineUsers.map((userID, index) => {
        return (
          <User userID={userID} key={index} />
        );
      })}
    </ComposableMap>
  );
};

export default Map;
