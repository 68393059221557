import firebase from 'firebase';

const firebaseConfig = {
  // apiKey:"AIzaSyALWLKF95hdKqDTNV9EQnTV5wFDypd_glo",
  // authDomain:"desibook-9f6b6.firebaseapp.com",
  // databaseURL:"https://desibook-9f6b6.firebaseio.com",
  // projectId:"desibook-9f6b6",
  // storageBucket:"desibook-9f6b6.appspot.com",
  // messagingSenderId:"253726224081",
  // appId:"1:253726224081:web:1ebf76041f9a1db998dff0",

  apiKey: "AIzaSyCfFyTgdZeI06mHGMyN_Rnbtp7ucmPlLgg",
  projectId: "linked-union",
  messagingSenderId: "187611190276",
  authDomain: "linked-union.firebaseapp.com",
  databaseURL: "https://linked-union.firebaseio.com/",
  storageBucket: "gs://linked-union.firebaseio.com/",

};

firebase.initializeApp(firebaseConfig);

let db;
try {
  // Check if Firebase is successfully initialized
  db = firebase.database();
} catch (error) {
  console.error("Error initializing Firebase database: ", error);
}
// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// }

export default db;
