import React, { useState, useEffect } from 'react';
import 'normalize.css';
import './Dashboard.css';
import Map from './Components/Map';
import twomaidslogo from './images/top-logo.svg';
import pointerMob from './images/mobileIcon.svg';
import pointerWeb from './images/webIcon.svg';
import pointerBackend from './images/backendIcon.svg';
import pointerOjt from './images/ojt.svg';
import pointerNotification from './images/notification.svg';
import db from './firebase';
// import IdleTimer from 'react-idle-timer'

function Dashboard() {
  const [onlineUserCount, setOnlineUserCount] = useState(0);

  const [mobileMember, setMobileMember] = useState(0);
  const [websiteMember, setwebMember] = useState(0);
  const [backendMember, setBackendMember] = useState(0);
  const [ojtUser, setOjtUser] = useState(0);
  const [notificationUser, setNotificationUser] = useState(0);

  const [registerdMember, setRegisterdMember] = useState(0);
  const [unregisterdMember, setUnregisterdMember] = useState(0);
  const [websiteCount, setwebsiteCount] = useState(0);
  const [happyClient, setHappyClient] = useState(0);
  const [monthlyServiceFee, setMonthlyServiceFee] = useState(0);
  const [upcomingClient, setUpcomingClient] = useState(0);

  // const [toggleKpiValue, setToggleKpiValue] = useState(false);

  const currentYear = new Date().getFullYear();

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const analyticsCount = async () => {
    try {
      const response = await fetch('https://pffi.admin.linkedunion.com/rest_api/get_all_analytics', { mode: 'cors' });

      const data = await response.json();
      if (data !== undefined && data?.data && typeof data?.data === 'object') {
        var websiteCount = data?.data?.ActiveWebsiteCount || 0;
        var newRegistered = data?.data?.RegisteredUser || 0;
        var newUnregistered = data?.data?.UnregisteredUser || 0;
        var happy_client = data?.data?.happyClients || 0;
        var upcoming_client = data?.data?.UpcomingClients || 0;
        var monthlyServiceFee = data?.data?.monthly_service_fee || 0;
      } else {
        console.error("Data is not an object or data is undefined: ", data);
      }
      // registered_user += newRegistered;
      // unregistered_user += newUnregistered;

      setHappyClient(happy_client);
      setUpcomingClient(upcoming_client);
      setwebsiteCount(websiteCount);
      setMonthlyServiceFee(monthlyServiceFee);
      // setRegisterdMember(registered_user?.toLocaleString('en'));
      setRegisterdMember(newRegistered?.toLocaleString('en'));
      // setUnregisterdMember(unregistered_user?.toLocaleString('en'));
      setUnregisterdMember(newUnregistered?.toLocaleString('en'));
    }
    catch (e) {
      console.error("Error fetching analytics data: ", e);
    }
  }
  // const userCount = async () => {
  //   try {
  //     const response = await fetch('https://linkedunion-apprenticeship.com/rest_api/users_count?app_id=1', { mode: 'cors' });
  //     const data = await response.json();
  //     console.log("User Count",data)
  //     const registered_member = data.registered_users;
  //     const unregistered_member = data.unregistered_users;
  //     registered_user = parseInt(registered_member);
  //     unregistered_user += parseInt(unregistered_member);

  //     setRegisterdMember(registered_user.toLocaleString('en'));
  //     setUnregisterdMember(unregistered_user.toLocaleString('en'));
  //   }
  //   catch (e) {
  //     console.error("Error fetching user data:", e);
  //   }
  // }

  useEffect(() => {
    analyticsCount();
    // userCount();
  }, [])

  let onlineUsersRef = db.ref('/analytics/');

  useEffect(() => {
    try {
      onlineUsersRef.on('value', (snapshot) => {
        const dummyData = {
          register_member: {},
          unregister_member: {}
        }
        const data = (snapshot !== undefined && snapshot?.val() !== undefined && snapshot?.val()) ? snapshot?.val() : dummyData;
        var count_array_register = [];
        count_array_register['backend'] = 0;
        count_array_register['web'] = 0;
        count_array_register['mobile'] = 0;
        count_array_register['notification'] = 0;
        count_array_register['ojt'] = 0;

        var count_array_unregister = [];
        count_array_unregister['backend'] = 0;
        count_array_unregister['web'] = 0;
        count_array_unregister['mobile'] = 0;
        count_array_unregister['notification'] = 0;
        count_array_unregister['ojt'] = 0;

        var register_data = data?.register_member || {};
        var unregister_data = data?.unregister_member || {};

        if (register_data) {
          for (var register_user in register_data) {
            if (register_data[register_user].device) {
              var device = register_data[register_user].device;
              count_array_register[device] = parseInt(count_array_register[device]) + 1
            }
          }
        } else {
          console.error("Registered Data is not an array or object in Dashboard");
        }

        if (unregister_data) {
          for (var unregister_user in unregister_data) {
            if (unregister_data[unregister_user].device) {
              var device1 = unregister_data[unregister_user].device;
              count_array_unregister[device1] = parseInt(count_array_unregister[device1]) + 1
            }
          }
        } else {
          console.error("Un-Registered Data is not an array or object in Dashboard file");
        }

        const active_mobile_member = (count_array_register?.mobile ? count_array_register?.mobile : 0) + (count_array_unregister?.mobile ? count_array_unregister?.mobile : 0);
        const active_web_member = (count_array_register?.web ? count_array_register?.web : 0) + (count_array_unregister?.web ? count_array_unregister?.web : 0);
        const active_backend_user = (count_array_register?.backend ? count_array_register?.backend : 0) + (count_array_unregister?.backend ? count_array_unregister?.backend : 0);
        const active_notification_user = (count_array_register?.notification ? count_array_register?.notification : 0) + (count_array_unregister?.notification ? count_array_unregister?.notification : 0);
        const active_ojt_user = (count_array_register?.ojt ? count_array_register?.ojt : 0) + (count_array_unregister?.ojt ? count_array_unregister?.ojt : 0);

        setMobileMember(active_mobile_member);
        setwebMember(active_web_member);
        setBackendMember(active_backend_user);
        setNotificationUser(active_notification_user);
        setOjtUser(active_ojt_user);
      })

    } catch (error) {
      console.error("Error in online user count: ", error)
    }
  }, []);

  const setTotalUsers = (users) => {
    setOnlineUserCount(users);
  };

  useEffect(() => {
    document.title = `LinkedUnion | Dashboard`;
  }, [onlineUserCount]);

  function kFormatter(num) {
    return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(3)) + 'MM' : Math.sign(num) * Math.abs(num)
  }
  function kMonthlyFormatter(num) {
    return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
  }

  var yearlyFee = kFormatter((monthlyServiceFee * 12) / 1000)
  var monthlyFee = kMonthlyFormatter(monthlyServiceFee)
  
  return (
    <div className='Dashboard'>
      <img
        src={twomaidslogo}
        alt='two-maids-logo'
        className='two-maids-logo'
      />{' '}
      <div className='total-monthly-revenue-text'>
        <span>
          {monthNames[new Date().getMonth()]} {new Date().getFullYear()}
        </span>
        <span className='total-yearly-revenue'>
          <span>
            ${yearlyFee}
          </span>
        </span>
        <span className="total-monthly-revenue"> ${monthlyFee}</span>

        <span className='average-transaction'>
          hAPPy Clients : {happyClient}
        </span>

        <span className='total-cleanings'>
          Upcoming Clients : {upcomingClient}
        </span>

        <span className='total-cleanings'>
          Active Website Count : {websiteCount}
        </span>
      </div>

      <div className='monthly-kpis'>
        <span className='kpi-group'>
          {' '}
          <span className='online-conversion-rate'>Registered Members :</span>
          <span className='result'>{registerdMember}</span>
        </span>
        <span className='kpi-group'>
          {' '}
          <span className='online-conversion-rate'>Unregistered Members :</span>
          <span className='result'>{unregisterdMember}</span>
        </span>
        <hr className='kpi-divider' />
        <span className='kpi-group'>
          {' '}
          <span className='online-conversion-rate'>Active Mobile Members :</span>
          <span className='result'>{mobileMember}</span>
        </span>
        <span className='kpi-group'>
          {' '}
          <span className='online-conversion-rate'>Active Website Members :</span>
          <span className='result'>{websiteMember}</span>
        </span>
        <span className='kpi-group'>
          {' '}
          <span className='online-conversion-rate'>Active Backend Members :</span>
          <span className='result'>{backendMember}</span>
        </span>
        <span className='kpi-group'>
          {' '}
          <span className='online-conversion-rate'>Active Notifications Users :</span>
          <span className='result'>{notificationUser}</span>
        </span>
        <span className='kpi-group'>
          {' '}
          <span className='online-conversion-rate'>Active OJT Hours Users :</span>
          <span className='result'>{ojtUser}</span>
        </span>

      </div>
      <Map setTotalUsers={setTotalUsers} />
      <div className='legend'>
        <div className='legend-item'>
          <span>Active Mobile Member</span>
          <hr />
          <img src={pointerMob} alt='' />
        </div>
        <div className='legend-item'>
          <span>Active Website Member</span>
          <hr />
          <img src={pointerBackend} alt='' />
        </div>
        <div className='legend-item'>
          <span>Active Backend Member</span>
          <hr />
          <img src={pointerWeb} alt='' />
        </div>
        <div className='legend-item'>
          <span>Active Notifications User</span>
          <hr />
          <img src={pointerNotification} alt='' />
        </div>
        <div className='legend-item'>
          <span>Active OJT Hours User</span>
          <hr />
          <img src={pointerOjt} alt='' />
        </div>
      </div>
      <div style={{ width: '100%' }}>

        <footer>
          <p>{currentYear} © Linked Union Software Suite.</p>
          <p>Linked Union, LLC.</p>
          <p>All Rights Reserved.</p>
          <p> Made with &#10084; in Eagle, Idaho.</p>
        </footer>
      </div>
    </div>
  );
}

export default Dashboard;
